import React from "react"
import cn from "classnames"
import Container from "~components/Container"

import Layout from "../layouts"
import SEO from "~components/SEO"
import { graphql, useStaticQuery } from "gatsby"

const Step = ({ className, children, ...props }) => {
  return (
    <li
      className={cn(
        className,
        "before:tw-inline-block before:tw-absolute before:tw-left-0 before:tw-h-7 before:tw-w-7 before:tw-bg-[image:var(--image-url)]"
      )}
      {...props}
    >
      {children}
    </li>
  )
}

const Reservation = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "svg/check-circle-regular.svg" }) {
        publicURL
      }
    }
  `)
  // const formik = useFormik({
  //   initialValues: {
  //     visit: "",
  //     date: "",
  //     firstName: "",
  //     lastName: "",
  //     email: "",
  //     participants: 0,
  //   },
  //   onSubmit: values => {
  //     console.log(JSON.stringify(values, null, 2))
  //   },
  // })

  return (
    <Layout>
      <SEO title="Réservation" />

      <section className="tw-mt-8">
        <Container>
          <h1 className="h1 tw-text-center">Réserver une visite</h1>

          <p className="subtitle tw-text-center">
            Partez à la découverte de Paris lors d’une visite guidée privée avec
            une guide-conférencier
          </p>
        </Container>
      </section>

      <section className="tw-mt-8">
        <Container>
          <h2 className="h2">Comment réserver ?</h2>
          <hr className="tw-mt-8" />

          <ul style={{ "--image-url": `url(${data.image.publicURL})` }}>
            <Step className="tw-relative tw-pl-8 tw-mb-6">
              Complétez votre demande de réservation ici
            </Step>
            <Step className="tw-relative tw-pl-8 tw-mb-6">
              Sous 72 heures maximum, je vous confirme la disponibilité et vous
              envoie un devis détaillé par email.
            </Step>
            <Step className="tw-relative tw-pl-8 tw-mb-6">
              Si cette proposition vous convient, vous confirmez votre commande
              en réglant un acompte.
            </Step>
            <Step className="tw-relative tw-pl-8 tw-mb-6">
              L’acompte peut être réglé par chèque ou par virement bancaire.
            </Step>
            <Step className="tw-relative tw-pl-8 tw-mb-6">
              Une fois le paiement effectué, je vous fais parvenir votre
              confirmation de réservation par email.
            </Step>
            <Step className="tw-relative tw-pl-8 tw-mb-6">
              Le solde sera à régler le jour de la visite.
            </Step>
            <Step className="tw-relative tw-pl-8 tw-mb-6">
              En cas d’annulation, l’annulation de la prestation est soumise aux
              dispositions mentionnées dans les conditions générales de vente.
            </Step>
          </ul>
        </Container>
      </section>

      <section className="tw-mt-8">
        <Container>
          {/* <Box>
            <H2>Comment réserver ?</H2>
            <hr className="tw-mt-8" />

            <form onSubmit={formik.handleSubmit}>
              <div>
                <label>
                  Visite
                  <select type="text">
                    <option />
                    <option />
                    <option />
                    <option />
                  </select>
                </label>
              </div>

              <div>
                <label>
                  Date de la visite
                  <input type="date" />
                </label>
              </div>

              <div>
                <label>
                  Nom
                  <input type="text" />
                </label>
              </div>

              <div>
                <label>
                  Prénom
                  <input type="text" />
                </label>
              </div>

              <div>
                <label>
                  Email
                  <input type="text" />
                </label>
              </div>

              <div>
                <label>
                  Téléphone
                  <input type="text" />
                </label>
              </div>

              <div>
                <label>
                  Nombre de personnes
                  <input type="text" />
                </label>
              </div>

              <div>
                <label>
                  <button>Envoyer</button>
                </label>
              </div>
            </form>
          </Box> */}
        </Container>
      </section>
    </Layout>
  )
}

export default Reservation
